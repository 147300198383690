import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers, getUser } from '../../Store/Actions/Actions';
import { useNavigate } from 'react-router-dom';

function Agencies() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { allUsers, userDetails } = useSelector(state => state.editor);
    const [key, setKey] = useState('');
    const agencyStatus = { '1': 'Active', '0': 'Inactive' };
    const [status, setStatus] = useState(''); // Initialize status with default values
    const [error, setError] = useState({ key: '', status: '' });
    const [isEdit, setIsEdit] = useState(false); // State to track edit mode
    const [editUserId, setEditUserId] = useState(null); // State to track which user is being edited
    let user = JSON.parse(localStorage.getItem('user'));
    const currentPath = window.location.pathname;
    // Function to populate form fields when in edit mode
    const populateForm = (userDetails) => {
        if (userDetails) {
            setKey(userDetails.key);
            setStatus(userDetails.status);
        }
    };
    useEffect(() => {
        // check if userDetails is not empty
        if (userDetails.length == 0) {
            setIsEdit(false);
            setEditUserId(null);
        }else{
            setIsEdit(true);
            setEditUserId(userDetails._id);
        }
    }, [userDetails]);
    useEffect(() => {
        // Check if we're in edit mode
        if (isEdit && editUserId && userDetails) {
            populateForm(userDetails);
        }
    }, [isEdit, editUserId,userDetails]);
    const handleSubmit = async (event) => {

        if (key.trim() === "" || status === "") {
            if (key.trim() === "") {
                setError({ name: "Key cannot be empty." });
                console.log("Key cannot be empty.");
            }
            if (status === "") {
                setError({ status: "please select status." });
                console.log("Status cannot be empty.");
            }
            return;
        }
        event.preventDefault();
        try {
            if (isEdit) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/editUser`, { id: editUserId, status: status}
                );
                if (response.data) {
                    toast.success("Agency updated successfully.");
                    setTimeout(() => {
                        dispatch(getAllUsers());
                        navigate(`/admin/`);
                    })
                    return;
                }
            }else{
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/addUser`, { createdBy: user._id, permission: 2, key: key, status: status }
                );
                if (response.data) {
                    toast.success("Agency added successfully.");
                    setTimeout(() => {
                        dispatch(getAllUsers());
                        navigate(`/admin/`);
                    })
                    return;
                }
            }
            
        } catch (error) {
            toast.error(error?.response?.data?.message);

        }
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "key") {
            setKey(value);
            setError({ ...error, key: value.trim() === "" ? "Key cannot be empty." : "" });
        }
        if (name === "status") {
            setStatus(value);
            setError({ ...error, status: value.trim() === "" ? "Please select status." : "" });
        }
    };

    return (
        <div className="templates">
            <div className="row">
                <div className="col-md-12 editimgcards">
                    <div className="toolbar-container card rounded-1 shadow-sm">
                        <div className="card-header bg-gray-200">
                            <div className='p-1 text-gray-900'>
                                {isEdit ? 'Edit Agency' : 'Add Agency'}
                            </div>
                        </div>
                        <div className="toolbar-content1">
                            <div className='row justify-content-left'>
                                <div className="mb-3 col-md-12">
                                    <label for="key" className="form-label">Key</label>
                                    <input value={key} onChange={handleChange} readOnly={isEdit ?'true':''} type="text" id="key" name="key" className="form-control" placeholder="Enter key" />
                                    <div className='text-danger'>{error.key}</div>
                                </div>
                            </div>
                            <div className='row justify-content-left'>
                                <div className="mb-3 col-md-12">
                                    <label for="status" className="form-label">Status</label>
                                    <select value={status} onChange={handleChange} id="status" name="status" className="form-control">
                                        <option value="">Select Status</option>
                                        {Object.entries(agencyStatus).map(([key, value]) => (
                                            <option key={key} value={key} selected={key === status}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='text-danger'>{error.status}</div>
                                </div>
                            </div>

                            <div className='text-center'>
                                <button type="submit " onClick={handleSubmit} className="btn btn-primary w-25">{isEdit ? 'Save' : 'Add'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Agencies;