import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllcategories} from '../../Store/Actions/Actions';

function AddCategories() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {category } = useSelector(state => state.editor);
    const [name, setName] = useState('');
    const [error, setError] = useState({ name: '' });
    const [isEdit, setIsEdit] = useState(false); // State to track edit mode
    const [editCategoryId, setEditCategoryId] = useState(null); // State to track which user is being edited
    let agency = JSON.parse(localStorage.getItem('user'));
    // Function to populate form fields when in edit mode
    const populateForm = (category) => {
        if (category) {
            setName(category.name);
        }
    };
    // Function to reset form state
    const resetForm = () => {
        setName('');
        setError({ name: '' });
        setIsEdit(false);
        setEditCategoryId(null);
    };
    useEffect(() => {
        if (category && category._id) {
            setIsEdit(true);
            setEditCategoryId(category._id);
            populateForm(category);
        } else {
            resetForm(); // Reset form state when no category is selected
        }
    }, [category]);
    useEffect(() => {
        // Check if we're in edit mode
        if (isEdit && editCategoryId && category) {
            populateForm(category);
        }else{
            resetForm();
        }
    }, [isEdit, editCategoryId]);

    const handleSubmit = async (event) => {

        if (name.trim() === ""){
            if (name.trim() === "") {
                setError({ name: "Name cannot be empty." });
            }
            return;
        }
        event.preventDefault();
        try {
            if (isEdit) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/agency/editCategory`, { name: name, id: editCategoryId }
                );
                if (response.data) {
                    toast.success("Category updated successfully.");
                    setTimeout(() => {
                        dispatch(getAllcategories(agency._id));
                        navigate(`/agency/`);
                    })
                    return;
                }
            }else{
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/agency/addCategory`, { name: name, createdBy: agency._id }
                );
                if (response.data) {
                    toast.success("Category added successfully.");
                    setTimeout(() => {
                        dispatch(getAllcategories(agency._id));
                        navigate(`/agency/`);
                    })
                    return;
                }
            }
            resetForm();
        } catch (error) {
            toast.error(error?.response?.data?.message);

        }
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "name") {
            if (value.trim() === "") {
                setError({ name: "Category cannot be empty." });
            } else {
                setError({ name: "" });
            }
            setName(value);
        }
        
    }
    return (
        <div className="templates">
            <div className="row">
                <div className="col-md-12 editimgcards">
                    <div className="toolbar-container card rounded-1 shadow-sm">
                        <div className="card-header bg-gray-200">
                            <div className='p-1 text-gray-900'>
                                {isEdit ? 'Edit Category' : 'Add Category'}
                            </div>
                        </div>
                        <div className="toolbar-content1">
                            <div className='row justify-content-left'>
                                <div className="mb-3 col-md-12">
                                    <label for="username" className="form-label">Name</label>
                                    <input value={name} onChange={handleChange} type="text" id="categoryname" name="name" className="form-control" placeholder="Enter category name" />
                                    <div className='text-danger'>{error.name}</div>
                                </div>
                            </div>
                            <div className='text-center'>
                                <button type="submit " onClick={handleSubmit} className="btn btn-primary w-25">{isEdit ? 'Save' : 'Add'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCategories;