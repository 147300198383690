import React from "react";
import "./App.css";
import Navbar from "./Navbar/Navbar";
import Studio from "./Studio/Studio";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Agencies from "./SuperAdmin/Agencies/Agencies";
import NotFound from "./NotFound/NotFound";
import ImageDetails from "./ImageDetails/ImageDetails";
import { ToastContainer, toast } from "react-toastify";
import { store } from "./Store";
import { Provider } from "react-redux";
import ImageEditor from "./ImageEditor/ImageEditor";
import PrivateRoute from "./PrivateRoute";
import { LogIn } from "./SuperAdmin/Login";
import SuperAdmin from "./SuperAdmin/SuperAdmin";
import Usage from "./Usage/usage";
import Categories from "./Agencies/Categories/Categories";
import AddCategories from "./Agencies/Categories/AddCategories";
import Image from "./Agencies/Categories/CategoryImage";
import ImageList from "./Agencies/Categories/CategoryImageList";
import AgencyImageEditor from "./Agencies/Categories/AgencyImageEditor";
import { useEffect } from 'react';
function App() {
  return (
    <Provider store={store}>
      <div className="container-main">
        <BrowserRouter basename="/">
          <Navbar />
          <Routes>
            <Route path="/not-found" element={<NotFound />} />
            {/* <Route element={<PrivateRoute />}> */}
            <Route path="/" element={<Studio />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/usage" element={<Usage />} />
            <Route path="admin/*">
              <Route index element={<SuperAdmin />} />
              <Route path="addagency" element={<Agencies />} />
              <Route path="editagency/:id" element={<Agencies />} />
              <Route path="login" element={<LogIn />} />
            </Route>
            <Route path="agency/*">
              <Route index element={<Categories />} />
              <Route path="addcategory" element={<AddCategories />} />
              <Route path="editcategory/:id" element={<AddCategories />} />
              <Route path=":categoryid/addtemplate" element={<Image />} />
              <Route path="image-editor" element={<AgencyImageEditor />} />
              <Route path=":categoryid/templates" element={<ImageList />} />
            </Route>
            <Route path="/image-details/:id" element={<ImageDetails />} />
            <Route path="/image-editor" element={<ImageEditor />} />
            {/* </Route> */}
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </div>
    </Provider>
  );
}
// create private route using react-router-dom v6 and above code
export default App;
