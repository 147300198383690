import axios from 'axios';
export const EDITOR_DETAILS = 'EDITOR_DETAILS';
export const IMAGE_DETAILS = 'IMAGE_DETAILS';
export const IMAGE_ACTIVITY = 'IMAGE_ACTIVITY';
export const ALL_USERS = 'ALL_USERS';
export const USER_DETAILS = 'USER_DETAILS';
export const CATEGORIES = 'CATEGORIES';
export const CATEGORY = 'CATEGORY';
export const CATEGORYIMAGES = 'CATEGORYIMAGES';
export const AGENCYIMAGES = 'AGENCYIMAGES';
export const USAGE_ACTIVITY = 'USAGE_ACTIVITY';
export const AGENCY_EDITOR_DETAILS = 'AGENCY_EDITOR_DETAILS';
export const AGENCY_USER_DETAILS = 'AGENCY_USER_DETAILS';
export function setEditorDetails(data, navigate) {
  return (dispatch) => {
    if (data.image) {
      dispatch({
        type: EDITOR_DETAILS,
        payload: data
      });
      navigate('/image-editor');
    }
  };
}
export function setAgencyEditorDetails(data, navigate) {
  return (dispatch) => {
    if (data.image) {
      dispatch({
        type: AGENCY_EDITOR_DETAILS,
        payload: data
      });
      navigate('/agency/image-editor');
    }
  };
}

export function getImageActivity(data, navigate) {
  return async (dispatch) => {
    try {
      const key = localStorage.getItem("key");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/image/stats/${data?._id}/?key=${key}`
      );
      console.log('Image activity:', response.data.results);
      if (response.data.results) {
        dispatch({
          type: IMAGE_ACTIVITY,
          payload: response.data.results
        });
      }
    } catch (error) {
      console.error('Error fetching image details:', error);
    }
  };
}

export function getUsageActivity(data, navigate) {
  return async (dispatch) => {
    try {
      const key = localStorage.getItem("key");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/image/usagestats/${data?._id}/?key=${key}`
      );
      console.log('Usage activity:', response.data.results);
      if (response.data.results) {
        dispatch({
          type: USAGE_ACTIVITY,
          payload: response.data.results
        });
      }
    } catch (error) {
      console.error('Error fetching image details:', error);
    }
  };
}

export function setImageDetails(id, navigate) {
  return async (dispatch) => {
    try {
      const key = localStorage.getItem("key");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/image/details/${id}/?key=${key}`
      );
      if (response.data) {
        dispatch({
          type: IMAGE_DETAILS,
          payload: response.data
        });
      }
    } catch (error) {
      console.error('Error fetching image details:', error);
    }
  };
}

export function getAllUsers(navigate) {
  return async (dispatch) => {
    try {
      const key = localStorage.getItem("key");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getUsers/?key=${key}`
      );
      if (response.data) {
        dispatch({
          type: ALL_USERS,
          payload: response.data.users
        });
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };
}

export function getUser(id, navigate) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getUser/${id}`
      );
      if (response.data) {
        dispatch({
          type: USER_DETAILS,
          payload: response.data.user
        });
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };
}
export function getUserbykey(id, navigate) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getUserbykey/${id}`
      );
      if (response.data) {
        dispatch({
          type: AGENCY_USER_DETAILS,
          payload: response.data.user
        });
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };
}
export function getAllcategories(id, navigate) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/agency/getCategories/${id}`
      );
      if (response.data) {
        dispatch({
          type: CATEGORIES,
          payload: response.data.categories
        });
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
}

export function getCategory(id, navigate) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/agency/getCategory/${id}`
      );
      if (response.data) {
        dispatch({
          type: CATEGORY,
          payload: response.data.category
        });
      }
    } catch (error) {
      console.error('Error fetching catgeory data:', error);
    }
  };
}

export function getCategoryAllImages(id, navigate) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/agency/allimages/${id}`
      );
      if (response.data) {
        dispatch({
          type: CATEGORYIMAGES,
          payload: response.data.categoryimages
        });
      }
    } catch (error) {
      console.error('Error fetching category images:', error);
    }
  };
}

export function getAgencyAllImages(id, navigate) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/agency/agencyallimages/${id}`
      );
      if (response.data) {
        dispatch({
          type: AGENCYIMAGES,
          payload: response.data.agencyimages
        });
      }
    } catch (error) {
      console.error('Error fetching agency images:', error);
    }
  };
}