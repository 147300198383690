// Usage page for the application
import React, { useState, useEffect } from 'react';
import ChartComponent from '../DataChart/Chart';
import { getUsageActivity } from '../Store/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdMonitor, MdOutlinePhoneIphone} from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Usage = () => {
  const { usageActivity } = useSelector((state) => state.editor);
  const dispatch = useDispatch();
  const todayDate = new Date().toISOString().slice(0, 10);
  const todayData = usageActivity?.filter((entry) => entry?.date === todayDate);
  const desktopData = todayData[0]?.devices.find((entry) => entry?.deviceType == 'desktop');
  const mobileData = todayData[0]?.devices.find((entry) => entry?.deviceType == 'mobile');
  const totalDevices = usageActivity.reduce((acc, entry) => acc + entry?.totalDevices, 0);
  const navigate = useNavigate();
  const notify = (text) => toast.success(text);
  const locationuser = JSON.parse(localStorage.getItem('location'));
  
  useEffect(() => {
    dispatch(getUsageActivity(locationuser[0]));
  }, []);
  return (
          <div className="templates col-sm-12">
                  <h5 className="font-semibold text-black mb-3 d-none">Overview</h5>
                  <div className="row mt-3">
                    <div className="col-md-3">
                      <div className="row mx-1">
                        <div className="card shadow-sm  p-0 pt-2">
                          <div className="card-header bg-white border-0">
                            <div className="">Total Views</div>
                          </div>
                          <div className="p-3 card-content d-flex align-items-center justify-content-between">
                            <div className="firstBox-t d-flex align-items-center justify-content-start">
                              <IoEye className="" color="var(--gray-300)" size={40} />
                            </div>
                            <div className="p-0">
                              <div className="secondBox-t d-flex align-items-center justify-content-end">
                                <div className="fs-2 text-dark text-bold">{totalDevices && totalDevices ? totalDevices && totalDevices : 0}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card 2 */}
                    <div className="col-md-3">
                      <div className="row mx-1">
                        <div className="card shadow-sm  p-0 pt-2">
                          <div className="card-header bg-white border-0">
                            <div>Today's Views</div>
                          </div>
                          <div className="p-3 card-content d-flex align-items-center justify-content-between">
                            <div className="firstBox-t d-flex align-items-center justify-content-start">
                              <IoEye className="" color="var(--gray-300)" size={40} />
                            </div>
                            <div className="p-0">
                              <div className="secondBox-t d-flex align-items-center justify-content-end">
                                <div className="fs-2 text-dark text-bold">{todayData[0]?.totalDevices ? todayData[0]?.totalDevices : 0}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* card 3 */}
                    <div className="col-md-3">
                      <div className="row mx-1">
                        <div className="card shadow-sm  p-0 pt-2">
                          <div className="card-header bg-white border-0">
                            <div>Desktop</div>
                          </div>
                          <div className="p-3 card-content d-flex align-items-center justify-content-between">
                            <div className="firstBox-t d-flex align-items-center justify-content-start">
                              <MdMonitor className="" color="var(--gray-300)" size={40} />
                            </div>
                            <div className="p-0">
                              <div className="secondBox-t d-flex align-items-center justify-content-end">
                                <div className="fs-2 text-dark text-bold">{desktopData?.count ? desktopData?.count : 0}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card 4 */}
                    <div className="col-md-3">
                      <div className="row mx-1">
                        <div className="card shadow-sm  p-0 pt-2">
                          <div className="card-header bg-white border-0">
                            <div>Mobile</div>
                          </div>
                          <div className="p-3 card-content d-flex align-items-center justify-content-between">
                            <div className="firstBox-t d-flex align-items-center justify-content-start">
                              <MdOutlinePhoneIphone className="" color="var(--gray-300)" size={40} />
                            </div>
                            <div className="p-0">
                              <div className="secondBox-t d-flex align-items-center justify-content-end">
                                <div className="fs-2 text-dark text-bold">{mobileData?.count ? mobileData?.count : 0}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-4">
                    <div className="col-md-12">
                      <div className="toolbar-container">
                        <div className="card shadow-sm bg-white ">
                          <div className="card-header bg-gray-100">
                            <div className="d-flex justify-content-between">Last 30 Days</div>
                          </div>
                          <div className="card-content">
                            <div className="toolbar-content" style={{ minHeight: '70px' }}>
                              {usageActivity.length > 0 && <ChartComponent chartData={usageActivity} />}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          </div>
  );
};

export default Usage;
