import {
  EDITOR_DETAILS,
  IMAGE_DETAILS,
  IMAGE_ACTIVITY,
  ALL_USERS,
  USER_DETAILS,
  CATEGORIES,
  CATEGORY,
  CATEGORYIMAGES,
  AGENCYIMAGES,
  USAGE_ACTIVITY,
  AGENCY_EDITOR_DETAILS,
  AGENCY_USER_DETAILS
} from '../Actions/Actions';
const initialState = {
  editorDetails: null,
  agencyeditorDetails: null,
  imageDetails: null,
  imageActivity: [],
  allUsers: [],
  userDetails: [],
  categories: [],
  category: [],
  allcategoryimages: [],
  allagencyimages: [],
  usageActivity: [],
  agencyuserDetails: []
};
export function EditorReducer(state = initialState, action) {
  if (action.type === EDITOR_DETAILS) {
    return {
      ...state,
      editorDetails: action.payload
    };
  }
  if (action.type === AGENCY_EDITOR_DETAILS) {
    return {
      ...state,
      agencyeditorDetails: action.payload
    };
  }
  if (action.type === IMAGE_DETAILS) {
    return {
      ...state,
      imageDetails: action.payload
    };
  }
  if (action.type === IMAGE_ACTIVITY) {
    return {
      ...state,
      imageActivity: action.payload
    };
  }
  if (action.type === USAGE_ACTIVITY) {
    return {
      ...state,
      usageActivity: action.payload
    };
  }
  if (action.type === ALL_USERS) {
    return {
      ...state,
      allUsers: action.payload
    };
  }
  if (action.type === USER_DETAILS) {
    return {
      ...state,
      userDetails: action.payload
    };
  }
  if (action.type === AGENCY_USER_DETAILS) {
    return {
      ...state,
      agencyuserDetails: action.payload
    };
  }
  if (action.type === CATEGORIES) {
    return {
      ...state,
      categories: action.payload
    };
  }
  if (action.type === CATEGORY) {
    return {
      ...state,
      category: action.payload
    };
  }
  if (action.type === CATEGORYIMAGES) {
    return {
      ...state,
      allcategoryimages: action.payload
    };
  }
  if (action.type === AGENCYIMAGES) {
    return {
      ...state,
      allagencyimages: action.payload
    };
  }
  return state;
}
