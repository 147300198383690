import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import {thunk} from 'redux-thunk';
import { EditorReducer } from '../Store/Reducers/EditorReducer';

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  editor: EditorReducer,
});
export const store = createStore(reducers, composeEnhancers(middleware));
