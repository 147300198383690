import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import LoadingSpinner from '../../components/LoadingSpinner';
import { setAgencyEditorDetails} from '../../Store/Actions/Actions';

const CategoryImage = () => {
  const fileTypes = ['JPG', 'PNG', 'GIF'];
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let user = JSON.parse(localStorage.getItem('user'));
  const { categoryid } = useParams();

  const handleChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let data = {
          image: e.target.result,
          url: location.pathname,
          categoryid: categoryid
        };
        dispatch(setAgencyEditorDetails(data, navigate));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="templates">
      <div className="row">
        <div className="col-md-12">
          <div className="toolbar-container">
            <div className="toolbar-title">
              Add Template
            </div>
            <div className="toolbar-content1">
              <div className="card my-4">
                <div className="card-body">
                <div id="upload-wrapper" className="col-md-12" style={{ display: 'block' }}>
                <FileUploader multiple={false} handleChange={handleChange} name="file" types={fileTypes} className="w-100">
                  <div className="drop-box mb-0" id="drop-zone" style={{ position: 'relative' }}>
                    <div>
                      <i className="fa-regular fa-image fa-3x"></i>
                    </div>
                    <h5>Drag Your Image here</h5>

                    <p>Drag your file here or click the button below to select one</p>
                    <button className="btn btn-primary rounded-1" id="browse-button" style={{ position: 'relative', zIndex: 1 }}>
                      Select Image
                    </button>
                    <div
                      id="html5_1hsrrps9e38k18gl10fv1quaano3_container"
                      className="moxie-shim moxie-shim-html5"
                      style={{ position: 'absolute', top: '119px', left: '345px', width: '153px', height: '36px', overflow: 'hidden', zIndex: 0 }}>
                      <input
                        id="html5_1hsrrps9e38k18gl10fv1quaano3"
                        type="file"
                        style={{ fontSize: '999px', opacity: 0, position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}
                        accept="image/jpeg,.jpg,.jpeg,image/gif,.gif,image/png,.png,image/svg+xml,.svg"
                      />
                    </div>
                  </div>
                </FileUploader>
              </div>
                  {/* <h5 className="card-title">Upload Image</h5>
                  <p className="card-text">Select a file and category to upload image</p>
                  <form onSubmit={handleSubmit} enctype="multipart/form-data">
                    <input type="file" onChange={handleFileChange} />
                    <button type="submit">Upload</button>
                  </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryImage;
