import React, { useEffect } from 'react';
import './Navbar.css';
import { TfiMenuAlt } from 'react-icons/tfi';
import { IoMdSettings } from 'react-icons/io';
import { useNavigate, NavLink } from 'react-router-dom';
import loadUser from '../utils/userVerification';

export default function Navbar() {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('k');
    const location = urlParams.get('l');
    const tokenFromLocalStorage = localStorage.getItem('key');
    if (!key && !tokenFromLocalStorage && !location) {
      navigate('/not-found');
    } else {
      let user = localStorage.getItem('user');
      if (!user) {
        loadUser(key ? key : tokenFromLocalStorage, location, navigate);
      }
    }
  }, []);

  return (
    <div>
      <div id="top-navbar" className="top-navbar d-flex align-items-center justify-content-between bg-white">
        <div className="d-flex align-items-center justify-content-start">
          <i className="fa-regular fa-hexagon-image fa-2x text-primary d-none"></i>
          <svg className="w-100 h1-00 d-none" width="35px" height="35px" viewBox="0 0 235 218" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M58.7565 100.595L84.204 115.018L109.419 129.845L109.651 159.095L109.419 188.345L84.204 203.171L58.7565 217.595L33.309 203.171L8.09398 188.345L7.86147 159.095L8.09398 129.845L33.309 115.018L58.7565 100.595Z"
              fill="#49CE27"></path>
            <path
              d="M116.756 0.594818L142.204 15.0185L167.419 29.8448L167.651 59.0948L167.419 88.3448L142.204 103.171L116.756 117.595L91.309 103.171L66.094 88.3448L65.8615 59.0948L66.094 29.8448L91.309 15.0185L116.756 0.594818Z"
              fill="#FCC300"></path>
            <path
              d="M175.756 100.595L201.204 115.018L226.419 129.845L226.651 159.095L226.419 188.345L201.204 203.171L175.756 217.595L150.309 203.171L125.094 188.345L124.861 159.095L125.094 129.845L150.309 115.018L175.756 100.595Z"
              fill="#2895FA"></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M43.1303 208.97L33.4949 203.508L29.2565 201.016L53.6459 156.556L44.1624 151.254C43.463 150.863 43.4844 149.85 44.1998 149.489L75.4789 133.703C76.1116 133.384 76.8665 133.806 76.9259 134.512L79.8621 169.425C79.9293 170.224 79.0771 170.773 78.3777 170.382L67.6027 164.358L43.1303 208.97Z"
              fill="white"></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M77.0568 136.068L79.8621 169.425C79.9293 170.224 79.0771 170.773 78.3777 170.382L67.6027 164.358L43.2273 208.793L58.7565 217.595L84.204 203.171L109.419 188.345L109.521 175.56L77.0568 136.068Z"
              fill="#287115"></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M43.1303 208.97L33.4949 203.508L29.2565 201.016L53.646 156.556L44.1624 151.254C43.463 150.863 43.4845 149.85 44.1998 149.489L75.4789 133.703C76.1116 133.384 76.8665 133.806 76.9259 134.512L79.8621 169.425C79.9293 170.224 79.0771 170.773 78.3777 170.382L67.6027 164.358L43.1303 208.97Z"
              fill="white"></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M77.0568 136.068L79.8621 169.425C79.9293 170.224 79.0771 170.773 78.3777 170.382L67.6027 164.358L43.2273 208.793L58.7565 217.595L84.204 203.171L109.419 188.345L109.521 175.56L77.0568 136.068Z"
              fill="#287115"></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M160.13 208.97L150.495 203.508L146.256 201.016L170.646 156.556L161.162 151.254C160.463 150.863 160.484 149.85 161.2 149.489L192.479 133.703C193.112 133.384 193.867 133.806 193.926 134.512L196.862 169.425C196.929 170.224 196.077 170.773 195.378 170.382L184.603 164.358L160.13 208.97Z"
              fill="white"></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M194.057 136.068L196.862 169.425C196.929 170.224 196.077 170.773 195.378 170.382L184.603 164.358L160.227 208.793L175.756 217.595L201.204 203.171L226.419 188.345L226.521 175.56L194.057 136.068Z"
              fill="#165289"></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M101.13 108.97L91.4949 103.508L87.2565 101.016L111.646 56.5559L102.162 51.2542C101.463 50.8632 101.484 49.8495 102.2 49.4885L133.479 33.7031C134.112 33.3837 134.867 33.8058 134.926 34.512L137.862 69.4254C137.929 70.2238 137.077 70.773 136.378 70.382L125.603 64.3583L101.13 108.97Z"
              fill="white"></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M135.057 36.0685L137.862 69.4254C137.929 70.2238 137.077 70.7731 136.378 70.3821L125.603 64.3584L101.227 108.793L116.756 117.595L142.204 103.171L167.419 88.3448L167.521 75.5605L135.057 36.0685Z"
              fill="#8A6B00"></path>
          </svg>
          <div onClick={() => { navigate('/'); }}
            className=" mx-2 h5 m-0 d-flex align-items-center justify-content-start cursor-pointer text-gray text-dark font-primary me-4">
            <span className="text-dark topmenu-navtitle">Smart Images</span>
          </div>
          {currentPath.includes('/admin') ? ( <>
            
          <div className=" mx-1 h5 m-0 d-flex align-items-center justify-content-start cursor-pointer text-gray text-dark font-primary">
            <NavLink
              to="/admin/" className={`nav-link btn-sm rounded-0 ${currentPath === '/admin/' ? 'active' : ''}`} end>Agencies</NavLink>
          </div>
          <div className=" mx-1 h5 m-0 d-flex align-items-center justify-content-start cursor-pointer text-gray text-dark font-primary">
              <NavLink
                to="/admin/addagency" className={`nav-link btn-sm rounded-0 ${currentPath === '/admin/addagency' ? 'active' : ''}`} end>Add Agency</NavLink>
            </div>
          </>
          ) : (currentPath.includes('/agency') ?( <>
            
            <div className=" mx-1 h5 m-0 d-flex align-items-center justify-content-start cursor-pointer text-gray text-dark font-primary">
              <NavLink
                to="/agency/" className={`nav-link btn-sm rounded-0 ${currentPath === '/agency/' ? 'active' : ''}`} end>Categories</NavLink>
            </div>
            <div className=" mx-1 h5 m-0 d-flex align-items-center justify-content-start cursor-pointer text-gray text-dark font-primary">
              <NavLink
                to="/agency/addcategory" className={`nav-link btn-sm rounded-0 ${currentPath === '/agency/addcategory' ? 'active' : ''}`} end>Add Category</NavLink>
            </div>
            </>
          ):(<>
          <div className=" mx-1 h5 m-0 d-flex align-items-center justify-content-start cursor-pointer text-gray text-dark font-primary" >
            <NavLink
              to="/dashboard" className={`nav-link btn-sm rounded-0 ${currentPath === '/dashboard' ? 'active' : ''}`} end>My Images</NavLink>
          </div>
          <div className=" mx-1 h5 m-0 d-flex align-items-center justify-content-start cursor-pointer text-gray text-dark font-primary" >
            <NavLink
              to="/usage" className={`nav-link btn-sm rounded-0 ${currentPath === '/usage' ? 'active' : ''}`} end>Usage</NavLink>
          </div>
          </>))}
          
        </div>
        {currentPath === '/dashboard' &&  currentPath === '/usage' && currentPath === '/' && (
        <div className="justify-content-end">
          <div className="d-flex align-items-center justify-content-end">
            <div>
              <div>
                {/* <a className="btn btn-default btn-sm mx-1 rounded-1" href="/dashboard">
                  <i className="fa-light fa-table-columns me-2"></i> Dashboard
                </a> */}
                <a className="btn btn-primary btn-sm mx-1 rounded-1" href="/">
                  <i className="fa-solid fa-plus me-2"></i> New image
                </a>
                <a className="btn btn-primary mx-1 btn-rounded-circle d-none" href="#" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                  <IoMdSettings size={18} color={'#fff'} />
                </a>
                <ul className="dropdown-menu menu-section shadow rounded-1 " aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item text-sm" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item text-sm" href="#">
                      Another action
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="d-none">
              <a className="nav-link " href="#" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <TfiMenuAlt size={30} color={'#666'} />
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a className="dropdown-item" href="/dashboard">
                    Dashboard
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/">
                    Create
                  </a>
                </li>
              </ul>

              <a className="nav-link " href="#" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <TfiMenuAlt size={30} color={'#666'} />
              </a>
            </div>
            <div className="d-none">
              <a className="nav-link " href="#" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <IoMdSettings size={30} color={'#666'} />
              </a>
              <ul className="dropdown-menu menu-section" aria-labelledby="navbarDropdown">
                <li>
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}
