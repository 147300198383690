import React, { useState, useEffect } from 'react';
import './ImageDetails.css';
import { FaPen } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoEye } from 'react-icons/io5';
import { MdMonitor, MdOutlinePhoneIphone, MdOutlineInsertLink, MdOutlineContentCopy } from 'react-icons/md';
import { IoMdPhoneLandscape } from 'react-icons/io';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DynamicInputs from './DynamicVariables';
import { getImageActivity, setEditorDetails, setImageDetails } from '../Store/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import ChartComponent from '../DataChart/Chart';
import LoadingSpinner from '../components/LoadingSpinner';

const ImageDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { imageDetails, imageActivity } = useSelector((state) => state.editor);
  const todayDate = new Date().toISOString().slice(0, 10);
  const todayData = imageActivity?.filter((entry) => entry?.date === todayDate);
  const desktopData = todayData[0]?.devices.find((entry) => entry?.deviceType == 'desktop');
  const mobileData = todayData[0]?.devices.find((entry) => entry?.deviceType == 'mobile');
  const totalDevices = imageActivity.reduce((acc, entry) => acc + entry?.totalDevices, 0);
  const location = useLocation();
  const navigate = useNavigate();
  let { id } = useParams();
  const dispatch = useDispatch();
  const notify = (text) => toast.success(text);
  const selectImageForEdit = () => {
    if (imageDetails?.designState?.imgSrc) {
      let data = {
        image: imageDetails?.designState.imgSrc,
        designState: imageDetails?.designState,
        name: imageDetails?.name,
        url: location?.pathname,
        id: imageDetails?._id,
      };
      dispatch(setEditorDetails(data, navigate));
    }
  };

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      dispatch(setImageDetails(id))
        .then(() => setIsLoading(false))
        .catch((error) => {
          setIsLoading(false);
        });
    };

    fetchData();
  }, [id, dispatch]);

  const [tagString, setTagString] = useState('');
  const handleUpdateTags = (newTagString) => {
    setTagString(newTagString);
  };
  const getMergeTags = (image) => {
    const annotations = image?.designState?.annotations;
    const mergeTags = [];
    const mergeTagsArr = [];
    for (const annotationId in annotations) {
      const annotation = annotations[annotationId];
      if (annotation?.mergTagName) {
        mergeTags.push(`${annotation?.mergTagName}=${annotation?.text}`);
        mergeTagsArr.push({ name: annotation?.mergTagName, value: annotation?.text });
      }
    }
    let querySTRINGS = '?';
    for (const mergeTag of mergeTags) {
      querySTRINGS += mergeTag + '&';
    }
    return { queryString: querySTRINGS.slice(0, -1), mergeTags: mergeTagsArr };
  };

  const copyToClipboard = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const mergeTags = getMergeTags(imageDetails).queryString;
    const htmlTag = `${apiUrl}/image/${imageDetails?._id}/${imageDetails?.name}${mergeTags}`;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(htmlTag).then(
        () => notify('Successfully copied!'),
        () => notify('Failed to copy!')
      );
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = htmlTag;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        notify('Successfully copied!');
      } catch (err) {
        notify('Failed to copy!');
      }
      document.body.removeChild(textarea);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between p-4 py-3">
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li className="nav-item me-1" role="presentation">
            <button
              className="btn btn-default rounded-1 btn-sm shadow-sm active"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true">
              Image Tag
            </button>
          </li>
          <li className="nav-item mx-1" role="presentation">
            <button
              className="btn btn-default rounded-1 btn-sm shadow-sm"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false">
              Live Preview
            </button>
          </li>
          <li
            className="nav-item mx-1"
            onClick={() => {
              dispatch(getImageActivity(imageDetails));
            }}
            role="presentation">
            <button
              className="btn btn-default rounded-1 btn-sm shadow-sm"
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-contact"
              type="button"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false">
              Activity
            </button>
          </li>
        </ul>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-start">
            <span className="text-lg mx-3 h5 p-0 pt-2 btn btn-default rounded-1 btn-sm shadow-sm mb-0 disabled">{imageDetails?.name}</span>
          </div>
          <button type="button" onClick={selectImageForEdit} className="btn btn-primary rounded-1 btn-sm shadow-sm pad-sm">
            <FaPen className="me-2" />
            Edit
          </button>
        </div>
      </div>

      {isLoading ? (
        <LoadingSpinner text="Loading image data..." />
      ) : (
        <>
          {imageDetails?._id ? (
            <div className="imageDetails p-4 py-1">
              <div className="tab-content p-0 py-2" id="pills-tabContent">
                <div className="tab-pane fade show p-0 py-3 active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <h3 className="h5">This is how you use your Smart Image:</h3>
                  <div className="row justify-content-center mt-3">
                    <div className="col-md-12">
                      <div className="toolbar-container card shadow-sm rounded-1">
                        <div className="toolbar-title d-flex justify-content-between card-header bg-gray-200 p-3 py-0">
                          Copy and paste this url in sms code of your email campaign
                          <div>
                            <MdOutlineInsertLink size={25} className="me-3" />
                            <MdOutlineContentCopy cursor={'pointer'} onClick={copyToClipboard} size={25} className="me-0" />
                          </div>
                        </div>
                        <div className="toolbar-content card-content" style={{ minHeight: '70px' }}>
                          <div className="code-container">
                            <code>
                              <span className="url-part">
                                {process.env.REACT_APP_API_URL}/image/{imageDetails?._id}/{imageDetails?.name}
                              </span>
                              <span className="query-part">{getMergeTags(imageDetails).queryString}</span>
                            </code>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade p-4 bg-white rounded-1" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="imageVar card shadow">
                        <div className="card-header p-4 py-2">Image Variables</div>
                        <div className="p-2">
                          <DynamicInputs image={imageDetails} onUpdateTags={handleUpdateTags} getMergeTags={getMergeTags} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9 ">
                      <img className="imagePreview shadow-sm rounded-1" src={`${process.env.REACT_APP_API_URL}/image/${imageDetails?._id}/${imageDetails?.name}?${tagString}`} alt="Preview" />
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <h5 className="font-semibold text-black mb-3 d-none">Overview</h5>
                  <div className="row mt-3">
                    <div className="col-md-3">
                      <div className="row mx-1">
                        <div className="card shadow-sm  p-0 pt-2">
                          <div className="card-header bg-white border-0">
                            <div className="">Total Views</div>
                          </div>
                          <div className="p-3 card-content d-flex align-items-center justify-content-between">
                            <div className="firstBox-t d-flex align-items-center justify-content-start">
                              <IoEye className="" color="var(--gray-300)" size={40} />
                            </div>
                            <div className="p-0">
                              <div className="secondBox-t d-flex align-items-center justify-content-end">
                                <div className="fs-2 text-dark text-bold">{totalDevices && totalDevices ? totalDevices && totalDevices : 0}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card 2 */}
                    <div className="col-md-3">
                      <div className="row mx-1">
                        <div className="card shadow-sm  p-0 pt-2">
                          <div className="card-header bg-white border-0">
                            <div>Today's Views</div>
                          </div>
                          <div className="p-3 card-content d-flex align-items-center justify-content-between">
                            <div className="firstBox-t d-flex align-items-center justify-content-start">
                              <IoEye className="" color="var(--gray-300)" size={40} />
                            </div>
                            <div className="p-0">
                              <div className="secondBox-t d-flex align-items-center justify-content-end">
                                <div className="fs-2 text-dark text-bold">{todayData[0]?.totalDevices ? todayData[0]?.totalDevices : 0}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* card 3 */}
                    <div className="col-md-3">
                      <div className="row mx-1">
                        <div className="card shadow-sm  p-0 pt-2">
                          <div className="card-header bg-white border-0">
                            <div>Desktop</div>
                          </div>
                          <div className="p-3 card-content d-flex align-items-center justify-content-between">
                            <div className="firstBox-t d-flex align-items-center justify-content-start">
                              <MdMonitor className="" color="var(--gray-300)" size={40} />
                            </div>
                            <div className="p-0">
                              <div className="secondBox-t d-flex align-items-center justify-content-end">
                                <div className="fs-2 text-dark text-bold">{desktopData?.count ? desktopData?.count : 0}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card 4 */}
                    <div className="col-md-3">
                      <div className="row mx-1">
                        <div className="card shadow-sm  p-0 pt-2">
                          <div className="card-header bg-white border-0">
                            <div>Mobile</div>
                          </div>
                          <div className="p-3 card-content d-flex align-items-center justify-content-between">
                            <div className="firstBox-t d-flex align-items-center justify-content-start">
                              <MdOutlinePhoneIphone className="" color="var(--gray-300)" size={40} />
                            </div>
                            <div className="p-0">
                              <div className="secondBox-t d-flex align-items-center justify-content-end">
                                <div className="fs-2 text-dark text-bold">{mobileData?.count ? mobileData?.count : 0}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-4">
                    <div className="col-md-12">
                      <div className="toolbar-container">
                        <div className="card shadow-sm bg-white ">
                          <div className="card-header bg-gray-100">
                            <div className="d-flex justify-content-between">Last 30 Days</div>
                          </div>
                          <div className="card-content">
                            <div className="toolbar-content" style={{ minHeight: '70px' }}>
                              {imageActivity.length > 0 && <ChartComponent chartData={imageActivity} />}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer position="top-right" autoClose={3000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
          ) : (
            <div className="text-center">
              <h4>No Image Found</h4>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ImageDetails;
