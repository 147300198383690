import React, { useEffect, useState } from 'react';

const NotFound = () => {
  const [message, setMessage] = useState('');
  useEffect(() => {
    const storedMessage = localStorage.getItem('message');
    if (storedMessage) {
      setMessage(storedMessage);
      // Optionally, remove the message after retrieval
      localStorage.removeItem('message');
    }
      
    }, []);
  return (
    <div>
        <h1 className='text-center mt-5'>{message}</h1>
    </div>
  )
}

export default NotFound