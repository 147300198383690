import React from 'react';
import axios from 'axios';


export function LogIn() {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
            email: username,
            password: password
            })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (

        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-4 shadow p-5">
                    <h2>Login</h2>
                    {/* {error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )} */}
                    <div className="mb-3">
                        <label for="username" className="form-label">Username</label>
                        <input onChange={(e) => { setUsername(e.target.value) }} type="text" id="username" className="form-control" placeholder="Enter username" />
                    </div>
                    <div className="mb-3">
                        <label for="password" className="form-label">Password</label>
                        <input type="password" onChange={(e) => { setPassword(e.target.value) }}  id="password" className="form-control" placeholder="Enter password" />
                    </div>
                    <button type="submit" onClick={handleSubmit} className="btn btn-primary">Login</button>
                </div>
            </div>
        </div>
    );
}
