import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useState, useEffect, useRef } from 'react';
import './Studio.css';
import loadUser from '../utils/userVerification';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setEditorDetails, getAllcategories, getAgencyAllImages } from '../Store/Actions/Actions';
import LoadingSpinner from '../components/LoadingSpinner';
import axios from 'axios';

const fileTypes = ['JPG', 'PNG', 'GIF'];
export default function Studio() {
  const [loading, setLoading] = useState(true);
  const { categories, allagencyimages} = useSelector(state => state.editor);
  const [imagesArray, setImagesArray] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeBtn, setACtiveBtn] = useState('ALL');
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get('k');
  const userlocation = urlParams.get('l');
  const allButtonRef = useRef(null);
  const fetchData = async () => {
    try {
      await loadUser(key, userlocation, navigate);
      let user = JSON.parse(localStorage.getItem('user'));
      if(user) {
        dispatch(getAllcategories(user._id));
        dispatch(getAgencyAllImages(user._id));
        setImagesArray(allagencyimages);
      } else{
        navigate('/not-found');
      }
        
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // Only run this effect on component mount
    const initialize = async () => {
      if (!key && !userlocation) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (!user || !user._id) {
          navigate('/not-found');
        } else {
          dispatch(getAllcategories(user._id));
          dispatch(getAgencyAllImages(user._id));
          setImagesArray(allagencyimages);
          setLoading(false);
        }
      } else {
        if (key && userlocation) {
          await fetchData();
        }
      }
    };

    initialize();
    
  }, []);
  useEffect(() => {
    // Trigger click event after a delay of 1000 milliseconds
    const delay = 1000; // Delay in milliseconds
    const timer = setTimeout(() => {
      if (allButtonRef.current) {
        allButtonRef.current.click();
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [allButtonRef]);
  const openImageEditor = (image, index) => {
    window.scrollTo(0, 0);
    if (image && image.src) {
      let data = {
        image: image.src,
        url: location.pathname,
        designState: image.designState,
      };
      dispatch(setEditorDetails(data, navigate));
    }
  };

  const handleChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let data = {
          image: e.target.result,
          url: location.pathname,
        };
        dispatch(setEditorDetails(data, navigate));
      };
      reader.readAsDataURL(file);
    }
  };

  const rotateImages = async(id) => {
    try {
      if(id === 'ALL') {
        setImagesArray(allagencyimages);
        setACtiveBtn(id);
        return;
      }else {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/agency/allimages/${id}`);
        setImagesArray(response.data.categoryimages);
        setACtiveBtn(id);
      }
    } catch (error) {
      console.error('Error loading images:', error);
    } 
  };

  return (
    <>
      {loading && <LoadingSpinner text="Initializing..." />}
      {!loading && (
        <div className="templates">
          <h2 className="font-semibold text-black hl-display-sm-medium">Create An Image</h2>
          <div className="studio-start-container p-0 py-2 mb-3">
            <div className="row">
              <div id="upload-wrapper" className="col-md-12" style={{ display: 'block' }}>
                <FileUploader multiple={false} handleChange={handleChange} name="file" types={fileTypes} className="w-100">
                  <div className="drop-box mb-0" id="drop-zone" style={{ position: 'relative' }}>
                    <div>
                      <i className="fa-regular fa-image fa-3x"></i>
                    </div>
                    <h5>Drag Your Image here</h5>

                    <p>Drag your file here or click the button below to select one</p>
                    <button className="btn btn-primary rounded-1" id="browse-button" style={{ position: 'relative', zIndex: 1 }}>
                      Select Image
                    </button>
                    <div
                      id="html5_1hsrrps9e38k18gl10fv1quaano3_container"
                      className="moxie-shim moxie-shim-html5"
                      style={{ position: 'absolute', top: '119px', left: '345px', width: '153px', height: '36px', overflow: 'hidden', zIndex: 0 }}>
                      <input
                        id="html5_1hsrrps9e38k18gl10fv1quaano3"
                        type="file"
                        style={{ fontSize: '999px', opacity: 0, position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}
                        accept="image/jpeg,.jpg,.jpeg,image/gif,.gif,image/png,.png,image/svg+xml,.svg"
                      />
                    </div>
                  </div>
                </FileUploader>
              </div>
              <div className="border-line d-none"></div>
            </div>
          </div>

          <div className="row">
            { categories && (
            <div className="col-md-2 col-6 com-sm-4 my-2" key="0">
              <div id="all_btn" ref={allButtonRef}
                className={activeBtn === 'ALL' ? 'featured-btns active-featured-btns' : 'featured-btns shadow-sm'}
                onClick={() => {
                rotateImages('ALL');
                }}>
                ALL
              </div>
            </div> )}
            {categories && categories.map((category, index) => (
              <div className="col-md-2 col-6 com-sm-4 my-2" key={index}>
                <div
                  className={activeBtn === category._id ? 'featured-btns active-featured-btns' : 'featured-btns shadow-sm'}
                  onClick={() => {
                    rotateImages(category._id);
                  }}>
                  {category.name}
                </div>
              </div>
            ))}
            <div className='clearfix'></div>
            {allagencyimages && allagencyimages.length > 0 && (<>
              {imagesArray && imagesArray.map((image, index) => (
                <div className="col-md-3 mb-0 mt-3" key={index}>
                  <div className="smart-image-container-wrap">
                    <div className="card shadow-sm mt-2 mb-1">
                      <div className="smart-image-container" onClick={() => openImageEditor(image, index)}>
                        <img src={image.src} className={'innerImg'} alt="image" />
                      </div>
                    </div>
                  </div>
                </div> 
              ))}</>
            )}
            
          </div>
        </div>
      )}
    </>
  );
}
