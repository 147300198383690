import axios from 'axios';

const loadUser = async (key, l= null, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/image/userVarification/?key=${key}`);
    localStorage.setItem('key', key);
    localStorage.removeItem('user');
    localStorage.removeItem('location');
    if (response.data.user) {
      localStorage.setItem('user', JSON.stringify(response.data.user));
      if (l !== null) {
        const locationuser = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getUserbykey/${l}`);
        
        if (locationuser.data.user.length > 0) {
          localStorage.setItem('location', JSON.stringify(locationuser.data.user));
        } else {
          const response1 = await axios.post(`${process.env.REACT_APP_API_URL}/admin/addUser`,
            { createdBy: response.data.user._id, permission: 3, key: l, status: 1 }
          );

          if (response1.data.user) {
            localStorage.setItem('location', JSON.stringify(response1.data.user));
          }
        }
      }
    }else{
      localStorage.setItem('message', JSON.stringify(response.data.message));
    }
  } catch (error) {
    localStorage.setItem('message', "User verification failed");
    navigate('/not-found');
  }
};
export default loadUser;
