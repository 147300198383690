import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers, getUser } from '../Store/Actions/Actions';
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

function SuperAdmin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { allUsers } = useSelector(state => state.editor);
    const [selectedAgencyForEdit, setSelectedAgencyForEdit] = useState(null);
    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);
    const editAgency = async (user) => {
        dispatch(getUser(user._id));
        navigate(`/admin/editagency/${user._id}`);
    }
    const deleteAgency = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/deleteUser/${selectedAgencyForEdit._id}`
            );
            if (response.data) {
                toast.success("Agency deleted successfully.");
                setSelectedAgencyForEdit(null);
                dispatch(getAllUsers());
                return;
            }
        } catch (error) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                console.error("Error deleting user:", error);
            }
        }
    };

    return (
        <div className="templates">
            <div className="row">
                <div className="col-md-12">
                    <div className="toolbar-container">
                        <div className="toolbar-title">
                           Agencies
                        </div>
                        <div className="toolbar-content">
                                <div>
                                    {allUsers && allUsers.map((user, index) => (
                                        <><div key={index} className="card my-4">
                                            <div className="card-body">
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <span className="card-title">{user.key}</span>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <span>{user.status === 1 ?'Active':'Inactive'}</span>
                                                    </div>
                                                    <div className='col-md-2 text-center'>
                                                        <FaEdit cursor={'pointer'} size={20} color="#267ed5" onClick={() => editAgency(user)}/>
                                                        <MdDeleteForever cursor={'pointer'} size={23} color="red" className='ms-3' 
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#staticBackdrop"
                                                        onClick={() => {
                                                            setSelectedAgencyForEdit(user);
                                                        }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="modal fade"
                                            id="staticBackdrop"
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            tabindex="-1"
                                            aria-labelledby="staticBackdropLabel"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-body text-center">
                                                        <h3 className="m-0">
                                                            Are you sure you want to delete this agency?
                                                        </h3>
                                                    </div>
                                                    <div className="d-flex justify-content-end p-3">
                                                        <button
                                                            type="button"
                                                            className="preview-btn px-3"
                                                            data-bs-dismiss="modal"
                                                            onClick={() => {
                                                                setSelectedAgencyForEdit(null);
                                                            }}
                                                        >
                                                            No
                                                        </button>
                                                        <button
                                                            type="button"
                                                            data-bs-dismiss="modal"
                                                            onClick={()=>{deleteAgency(user)}}
                                                            className="preview-btn bg-danger ms-3 px-3"
                                                        >
                                                            Yes
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></>
                                    )
                                    )}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuperAdmin;