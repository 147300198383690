import React, { useState, useEffect } from 'react';

const DynamicInputs = ({ image, onUpdateTags, getMergeTags }) => {
  const initialTagString = getMergeTags(image).mergeTags;
  const [tags, setTags] = useState(initialTagString);
  const handleChange = (e) => {
    const { name, value } = e.target;
    //get index and update the value
    const index = tags.findIndex((tag) => tag.name === name);
    const newTags = [...tags];
    newTags[index].value = value;
    setTags(newTags);
  };

  useEffect(() => {
    if (image) {
      setTags(getMergeTags(image).mergeTags);
    }
  }, [image]);
  useEffect(() => {
    onUpdateTags(generateTagString());
  }, [tags]);

  const generateTagString = () => {
    return tags.map((tag) => `${tag.name}=${tag.value}`).join('&');
  };
  return (
    <div>
      {tags.length > 0 ? (
        tags.map((tag) => (
          <div key={tag}>
            <label className="wrapper">{tag.name}</label>
            <input type="text" name={tag.name} value={tag.value} onChange={handleChange} />
          </div>
        ))
      ) : (
        <h6 className="text-left p-3 py-2">No Image Variables</h6>
      )}
    </div>
  );
};

export default DynamicInputs;
