import React from 'react';

const LoadingSpinner = ({ text }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center spinner-wrapper">
      <div className="spinner-border text-primary" role="status"></div>
      <div className="loading-fresh-data text-sm mt-2" role="status">
        <p className="text-xs text-center p-0 m-0">{text}</p>
      </div>
    </div>
  );
};

export default LoadingSpinner;
