import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllcategories, getCategory, getUserbykey } from '../../Store/Actions/Actions';
import { FaImage, FaEdit, FaPlusSquare } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import loadUser from '../../utils/userVerification';

function Categories() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categories, agencyuserDetails} = useSelector(state => state.editor);
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('k');
    
    useEffect(() => {
        const tokenFromLocalStorage = localStorage.getItem('key');
        if (!key && !tokenFromLocalStorage) {
          navigate('/not-found');
        } else {
            if(key == tokenFromLocalStorage){ 
                let agency = localStorage.getItem('user');
            }else{

            loadUser(key ? key : tokenFromLocalStorage, navigate);
          }
        }
    }, []);
    let agency = JSON.parse(localStorage.getItem('user')); 
    const [selectedCategoryForEdit, setSelectedCategoryForEdit] = useState(null);
    
    useEffect(() => {
        const fetchData = async () => {
            if(agency && agency._id){
                dispatch(getAllcategories(agency._id));
            }else{
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/admin/getUserbykey/${key}`
                );
                if (response.data) {
                    const agency1 = response.data.user[0];
                    dispatch(getAllcategories(agency1._id))
                }
            }
        };
        fetchData();
    }, []);

    const editCatgeory = async (category) => {
        dispatch(getCategory(category._id));
        navigate(`/agency/editcategory/${category._id}`);
    }

    const deleteCategory = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/agency/deleteCategory/${selectedCategoryForEdit._id}`
            );
            if (response.data) {
                toast.success("Catgeory deleted successfully.");
                setSelectedCategoryForEdit(null);
                dispatch(getAllcategories(agency._id));
                return;
            }
        } catch (error) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                console.error("Error deleting category:", error);
            }
        }
    };
    return (
        <div className="templates">
            <div className="row">
                <div className="col-md-12">
                    <div className="toolbar-container">
                        <div className="toolbar-title">
                            Categories 
                        </div>
                        <div className="toolbar-content1">
                            <div>
                                {categories && categories.map((category, index) => (
                                    <><div key={index} className="card my-4">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <h5 className="card-title">{category.name}</h5>
                                                </div>
                                                <div className='col-md-6'>
                                                    <span></span>
                                                </div>
                                                <div className='col-md-2 text-center'>
                                                    <FaImage cursor={'pointer'} size={20} color="#267ed5" onClick={() => navigate(`/agency/${category._id}/templates`)}/>
                                                    <FaPlusSquare cursor={'pointer'} size={20} color="#267ed5" className='ms-3' onClick={() => navigate(`/agency/${category._id}/addtemplate`)}/>
                                                    <FaEdit cursor={'pointer'} size={20} color="#267ed5" className='ms-3'onClick={() => editCatgeory(category)}/>
                                                    <MdDeleteForever cursor={'pointer'} size={23} color="red" className='ms-3' 
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#staticBackdrop"
                                                    onClick={() => {
                                                        setSelectedCategoryForEdit(category);
                                                    }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="staticBackdrop"
                                        data-bs-backdrop="static"
                                        data-bs-keyboard="false"
                                        tabindex="-1"
                                        aria-labelledby="staticBackdropLabel"
                                        aria-hidden="true"
                                    >
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-body text-center">
                                                    <h3 className="m-0">
                                                        Are you sure you want to delete this category?
                                                    </h3>
                                                </div>
                                                <div className="d-flex justify-content-end p-3">
                                                    <button
                                                        type="button"
                                                        className="preview-btn px-3"
                                                        data-bs-dismiss="modal"
                                                        onClick={() => {
                                                            setSelectedCategoryForEdit(null);
                                                        }}
                                                    >
                                                        No
                                                    </button>
                                                    <button
                                                        type="button"
                                                        data-bs-dismiss="modal"
                                                        onClick={()=>{deleteCategory(category)}}
                                                        className="preview-btn bg-danger ms-3 px-3"
                                                    >
                                                        Yes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div></>
                                )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Categories;