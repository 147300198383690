import React from 'react';
import './Dashboard.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import loadUser from '../utils/userVerification';
import { setImageDetails } from '../Store/Actions/Actions';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../components/LoadingSpinner';

const Dashboard = () => {
  const [editedImages, setEditedImages] = useState([]);
  const [selectedImageForEdit, setSelectedImageForEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isImgEditorShown, setIsImgEditorShown] = useState(false);
  const [content, setContent] = useState('myImages');

  const loadImages = async () => {
    try {
      const key = localStorage.getItem('key');
      const location = JSON.parse(localStorage.getItem('location'));
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/image/getimages/${location[0]._id}?key=${key}`);
      setEditedImages(response.data);
    } catch (error) {
      console.error('Error saving image:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadImages();
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('k');
    const location = urlParams.get('l');
    const tokenFromLocalStorage = localStorage.getItem('key');
    if (!key && !tokenFromLocalStorage) {
      navigate('/not-found');
    } else {
      let user = localStorage.getItem('user');
      if (!user) {
        loadUser(key ? key : tokenFromLocalStorage, location,  navigate);
      }
    }
  }, []);

  const livePreview = (image) => {
    navigate(`/image-details/${image._id}`);
  };

  const deleteImage = async () => {
    try {
      const key = localStorage.getItem('key');
      const location = localStorage.getItem('location');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/image/${selectedImageForEdit._id}?key=${key}`);
      loadImages();
    } catch (error) {
      console.error('Error saving image:', error);
    }
  };

  return (
    <div className="templates">
      <h2 className="font-semibold text-black hl-display-sm-medium mb-3">My Smart Images</h2>
      <div className="row">
        <div className="col-md-3 d-none">
          <div className="toolbar-container  card rounded-1 shadow-sm shadow-sm-hover">
            <div className="card-header bg-primary">
              <div className="p-1 text-white">Folder</div>
            </div>

            <div className="toolbar-content card-content p-4 mt-3 mb-3 py-0">
              <div
                className="border-bottom border-1 dropdown-item text-sm rounded-1 mt-2 mb-1 p-3 py-2"
                style={{ color: 'var(--primary-600)' }}
                onClick={() => {
                  setContent('myImages');
                }}>
                My Images
              </div>
              <div
                className="border-bottom border-1 dropdown-item text-sm rounded-1 mt-2 mb-1 p-3 py-2"
                onClick={() => {
                  setContent('dataSources');
                }}>
                Data Sources
              </div>
              <div
                className="border-bottom border-1 dropdown-item text-sm rounded-1 mt-2 mb-3 p-3 py-2"
                onClick={() => {
                  setContent('usage');
                }}>
                Usage
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 editimgcards">
          {isLoading ? (
            <LoadingSpinner text="Loading Smart Images..." />
          ) : (
            !isImgEditorShown && (
              <div className="toolbar-container card rounded-1 shadow-sm ">
                <div className="card-header bg-gray-200">
                  <div className="p-1 text-gray-900">Folder : All Images ({editedImages.length})</div>
                </div>

                <div className="toolbar-content card-content p-4 bg-white">
                  <div className="row p-0 m-0">
                    {editedImages.length > 0 ? (
                      editedImages.map((image, index) => (
                        <>
                          <div className="col-md-3">
                            <div className=" card shadow-sm p-2 m-0 smart-image-container-wrap">
                              <div className="card-content">
                                <div className="p-0">
                                  <img
                                    onClick={() => {
                                      livePreview(image);
                                    }}
                                    className=""
                                    src={image.src}
                                    alt={image.name}
                                    key={index}
                                    width={'100%'}
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center mx-2 p-3 mb-2 card-footer">
                                <button
                                  className="preview-btn btn text-white text-xs"
                                  onClick={() => {
                                    livePreview(image);
                                  }}>
                                  <i className="fa-solid fa-eye me-1"></i> View
                                </button>
                                <button
                                  className="preview-btn btn btn-default text-white text-xs"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                  onClick={() => {
                                    setSelectedImageForEdit(image);
                                  }}>
                                  <i className="fa-solid fa-trash me-1"></i>
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <h3 className="text-center">No images found</h3>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div className="modal fade rounded-1" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content  p-2">
            <div className="modal-body text-center  p-4">
              <div>
                <i className="fa-thin fa-circle-exclamation fa-5x text-notice mb-3"></i>
                <h2 className="m-0 text-primary">Attention!</h2>
                <h3 className="m-0 p-0 mb-3">Are you sure you want to delete this image?</h3>
              </div>
              <div className="d-flex justify-content-center mb-3">
                <button
                  type="button"
                  className="preview-btn px-3 btn btn-primary rounded-1"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setSelectedImageForEdit(null);
                  }}>
                  Cancel
                </button>
                <button type="button" data-bs-dismiss="modal" onClick={deleteImage} className="preview-btn btn btn-primary bg-danger ms-3 px-3 rounded-1">
                  <i className="fa-solid fa-trash-xmark me-1"></i> Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
