import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const ChartComponent = ({ chartData }) => {
    const labels = chartData.map(item => item.date);
    const dataCounts = chartData.map(item => item.totalDevices);
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Views',
                data: dataCounts,
                backgroundColor: '#73b076',
            }
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return (
        <div>
            <Bar data={data} options={options} />
        </div>
    );
};

export default ChartComponent;
