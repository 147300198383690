import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { setAgencyEditorDetails} from '../../Store/Actions/Actions';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner';
function CategoryImageList() {
    const [categoryImages, setCategoryImages] = useState([]);
    const [selectedImageForEdit, setSelectedImageForEdit] = useState(null);
    const { categoryid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loadImages = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/agency/allimages/${categoryid}`);
          setCategoryImages(response.data.categoryimages);
        } catch (error) {
          console.error('Error loading images:', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        loadImages();
    }
    , []);
    const selectTemplateForEdit = (image) => {
        let data = {
          image: image.designState?image.designState.imgSrc:image.src,
          designState: image.designState?image.designState:'',
          name: image.name,
          url: location.pathname,
          id: image._id,
          categoryid: image.category
        };
        dispatch(setAgencyEditorDetails(data, navigate));
      };
    const deleteImage = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/agency/deleteImage/${selectedImageForEdit._id}`
            );
            if (response.data) {
                toast.success("Image deleted successfully.");
                setSelectedImageForEdit(null);
                loadImages();
                return;
            }
        } catch (error) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                console.error("Error deleting image:", error);
            }
        }
    };
    return (
        <div className="templates">
            <h2 className="font-semibold text-black hl-display-sm-medium mb-3">Templates</h2>
            <div className="row">
                <div className="col-md-12 editimgcards">
                    <div className="toolbar-container card rounded-1 shadow-sm ">
                        <div className="card-header bg-gray-200">
                        <div className="p-1 text-gray-900">All Templates</div>
                    </div>
                    {isLoading ? (
                        <LoadingSpinner text="Loading Smart Images..." />
                    ) : (
                    <div className="toolbar-content card-content p-4 bg-white">
                        <div className="row p-0 m-0">
                            {categoryImages.length > 0 ? (
                            categoryImages.map((image, index) => (
                                <>
                                <div className="col-md-3">
                                    <div className=" card shadow-sm p-2 m-0 smart-image-container-wrap">
                                    <div className="card-content">
                                        <div className="p-0">
                                        <img
                                            className=""
                                            src={image.src}
                                            alt='image'
                                            key={index}
                                            width={'100%'}
                                        />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mx-2 p-3 mb-2 card-footer">
                                        <button
                                        className="preview-btn btn text-white text-xs"
                                        onClick={() => { selectTemplateForEdit(image) }}>
                                        <i className="fa-solid fa-eye me-1"></i> Edit
                                        </button>
                                        <button
                                        className="preview-btn btn btn-default text-white text-xs"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop"
                                        onClick={() => {
                                            setSelectedImageForEdit(image);
                                        }}>
                                        <i className="fa-solid fa-trash me-1"></i>
                                        Delete
                                        </button>
                                    </div>
                                    </div>
                                </div>
                                </>
                            ))
                            ) : (
                            <h3 className="text-center">No images found</h3>
                            )}
                        </div>
                    </div>
                    )}
                </div>
            </div>
            <div
                className="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <h3 className="m-0">
                                Are you sure you want to delete this image?
                            </h3>
                        </div>
                        <div className="d-flex justify-content-end p-3">
                            <button
                                type="button"
                                className="preview-btn px-3"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setSelectedImageForEdit(null);
                                }}
                            >
                                No
                            </button>
                            <button
                                type="button"
                                data-bs-dismiss="modal"
                                onClick={()=>{deleteImage()}}
                                className="preview-btn bg-danger ms-3 px-3"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export default CategoryImageList;