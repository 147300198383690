import React, { useEffect, useState } from 'react';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner';
const AgencyImageEditor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { agencyeditorDetails } = useSelector((state) => state.editor);
  const [customfontsarray, setCustomFontsArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchfonts = async () => {
    try {
      const response =  await axios.get(`${process.env.REACT_APP_API_URL}/customFonts`);
      const fonts = response.data
      if(fonts){
        const fontsArray = Object.keys(fonts).map((key) => ({
          label: fonts[key].fontname,
          value: fonts[key].fontfamily
        }));
        
        setCustomFontsArray(fontsArray);
      }
    }catch (error) {
      console.error('Error fetching fonts:', error);
    } finally {
      setLoading(false); // Ensure loading is set to false when done
    }
  }
  useEffect(()=>{
    fetchfonts();
  },[]);
  
  const onEditImageSave = async (editedImageObject, designState) => {
    try {
      for (const key in designState.annotations) {
        if (key.includes('MergeTag')) {
          if (designState.annotations[key].mergTagName) {
            designState.annotations[key].mergTagName = designState.annotations[key].mergTagName.replace(/\s+/g, '-');
          } else {
            designState.annotations[key].mergTagName = 'default';
          }
        }
      }

      let user = JSON.parse(localStorage.getItem('user'));
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agency/addtemplate`, {
        name: editedImageObject.fullName,
        src: editedImageObject.imageBase64,
        designState,
        createdBy: user._id,
        category : agencyeditorDetails.categoryid,
        id:agencyeditorDetails.id?agencyeditorDetails.id:null
      });
      navigate(`/agency/${agencyeditorDetails.categoryid}/templates`);
    } catch (error) {
      console.error('Error saving temaplate:', error);
    }
  };
  const closeImgEditor = () => {
    navigate(agencyeditorDetails.url);
  };
  return (
    <>
      {loading ? (<LoadingSpinner text="Loading image..." />) : ((agencyeditorDetails?.image && customfontsarray.length > 0)? (
        <div className="image-editor-container image-editor-container-new">
          <FilerobotImageEditor
            source={agencyeditorDetails?.image}
            onSave={onEditImageSave}
            onClose={closeImgEditor}
            loadableDesignState={agencyeditorDetails?.designState ? agencyeditorDetails.designState : null}
            annotationsCommon={{
              fill: '#003cff',
            }}
            Text={{
              text: ['Default text'],
              fonts: customfontsarray,
              fill: '#000000',
            }}
            MergeTag={{
              text: ['Default text'],
              fonts: customfontsarray,
              fill: '#000000',
            }}
            Rotate={{ angle: 90, componentType: 'slider' }}
            tabsIds={[TABS.ANNOTATE]}
            defaultTabId={TABS.ANNOTATE} // or 'Annotate'
            defaultToolId={TOOLS.TEXT} // or 'Text'
            defaultSavedImageName={agencyeditorDetails?.name ? agencyeditorDetails?.name : 'image'}
            disableZooming={true}
          />
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center spinner-wrapper">
          <i className="fa-regular fa-image fa-3x text-gray"></i>
          <div className="py-2 text-center">
            <h5 className="text-center p-0 m-0 h5 text-danger">Something went wrong!</h5>
            <p className="text-center p-0 m-0 py-1">There is no image to edit.</p>
            <button className="btn btn-default btn-sm mt-3" onClick={() => navigate(`/agency/${agencyeditorDetails.categoryid}/addtemplate`)}>
              <i className="fa-solid fa-arrow-left me-2"></i>Back
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default AgencyImageEditor;
